import logo from './logo.svg';
import Nahida from'./Nahida.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <img src={Nahida} className="App-logo" alt="Nahida" />
        <p>
        Web เพื่อการศึกษา
        </p>

        <a
          className="App-link"
          href="https://www.facebook.com/profile.php?id=100009257066013"
          target="_blank"
          rel="noopener noreferrer"
        >
          My-Facebook
        </a>
        <a
        className="App-link1"
        href ="https://drive.google.com/drive/folders/1-Y2RB_NZneLGOCMGoZifFZaicAgu8E6t?usp=sharing">
          recovery A13 twrp
        </a>
        <a
        className="App-link1"
        href ="https://hatyaitech-my.sharepoint.com/:f:/g/personal/64209010021_rms_htc_ac_th/Evv1YAIULEFLhR9n46l5za0Bf1cVJx3JZ937D8apHzs2oQ?e=psppb6">
          blendee
        </a>
      </header>
    </div>
  );
}

export default App;
